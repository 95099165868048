import React from 'react';
import styles from './index.module.css';
import { managerRest } from '../../index';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    void managerRest.createClientLog({
      type: 'error',
      client: 'partner_backoffice',
      data: { error, errorInfo },
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.container}>
          <h1>Oooops..</h1>
          <h3>Something went wrong</h3>
        </div>
      );
    }

    return this.props.children;
  }
}
