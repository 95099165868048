export default {
  EPCFN_DISABLE_BUY_BONUS: 'disableBuyBonus',
  EPCFN_CURRENCIES: 'currencies',
  EPCFN_SHOW_OPERATOR_BETS_IDS: 'showOperatorBetsIds',
  EPCFN_SHOW_RULES_ON_START: 'showRulesOnStart',
  EPCFN_HIDE_RULES_DOWNLOAD_BUTTON: 'hideRulesDownloadButton',
  EPCFN_IFRAME_HOME_BUTTON: 'iframe_home_button',
  EPCFN_HOME_BUTTON_HISTORY_BACK_DISABLE: 'homeButtonHistoryBackDisable',
  EPCFN_SESSION: 'session',
  EPCFN_CANCEL_ENABLED: 'cancelEnabled',
  EPCFN_IS_MAX_BETS_COUNT_ONE: 'isMaxBetsCountOne',
  EPCFN_MAX_WIN_LIMIT: 'maxWinLimit',
  EPCFN_FREE_BETS: 'freebets',
  EPCFN_SHOW_MAX_WIN_ON_START: 'showMaxWinOnStart',
  EPCFN_CUSTOM_RTP: 'customRtp',
  EPCFN_DISPLAY_RTP: 'displayRtp',
  EPCFN_SHOW_NET_POSITION: 'showNetPosition',
  EPCFN_SHOW_GAME_STATE: 'showGameState',
  EPCFN_JACKPOT_ENABLED: 'jackpotEnabled',
  EPCFN_JACKPOT_PAYOUT_THRESHOLD: 'jackpotPayoutThreshold',
  EPCFN_AVAILABLE_BETS: 'available_bets',
  EPCFN_MESSAGE_EVENTS: 'messageEvents',
  EPCFN_DISABLE_AUTOPLAY: 'disableAutoplay',
  EPCFN_ROUND_DURATION: 'roundDuration',
  EPCFN_DISABLE_SPIN_BY_SPACE: 'disableSpinBySpace',
  EPCFN_SHOW_STOPWATCH: 'showStopwatch',
  EPCFN_SHOW_ClOCK: 'showClock',
  EPCFN_ENABLE_REALITY_CHECK: 'enableRealityCheck',
  EPCFN_SKIP_BLOCKED: 'skipBlocked',
  EPCFN_CERTIFICATION_NAME: 'certificationName',
  EPCFN_LIGHT_MODE: 'lightMode',
  EPCFN_SLOT_MODE: 'slotMode',
  EPCFN_HIDE_NON_PLAYER_CURRENCIES: 'hideNonPlayerCurrencies',
  EPCFN_BET_INDEX_CUSTOM_RANGE_SETUP: 'betIndexCustomRangeSetup',
  EPCFN_ENABLE_SOCIAL_LANGUAGE: 'enableSocialLanguage',
  EPCFN_CUSTOM_RESOURCE_POOL: 'customResourcePool',
  EPCFN_USE_LOW_QUALITY_ASSETS: 'useLowQualityAssets',
  EPCFN_IS_ROUNDS_HISTORY_AVAILABLE: 'isRoundsHistoryAvailable',
  EPCFN_TOURNAMENT_PAYOUT_CALLBACK_URL: 'tournamentPayoutCallbackUrl',
  EPCFN_IS_FEEDBACK_ENABLED: 'isFeedbackEnabled',
  EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES: 'crushGamesBetButtonValues',
}
