import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../redux/partnersConfig/selectors';
import eOperatorsConfigsFieldName from '../../eOperatorsConfigsFieldName';
import BetLevels from './betLevels/betLevels';
import DropdownLayout from '../../../dropdownLayout';

export default React.memo(forwardRef(function CrushGamesBetButtonValues({
                                                         configForView,
                                                         canChangeBlock,
                                                         canOverride,
                                                         setSaveFunctionPool,
                                                         overriddenBlocks,
                                                         toggleOverriddenField,
                                                         setValueToConfigForView
                                                       }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    bet_levels: null,
  }), []);
  const [formData, setFormData] = useState(defaultFormData);
  const betLevelsRef = useRef();

  const saveFunction = useCallback(() => {
    const data = {};
    let betsData = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES] === configForView.type) {

      if (formData.bet_levels) {
        let betLevels;
        const availableBetsKey = Object.keys(formData.bet_levels);
        if (availableBetsKey.length) betLevels = availableBetsKey.reduce((prev, currency) => {
          prev[currency.toLowerCase()] = formData.bet_levels[currency];
          return prev;
        }, {});
        betsData = betLevels;
      }

      if (formData.bet_levels) data[eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES] = betsData;
    }
    return data;
  }, [formData, overriddenBlocks, configForView.type, configForView.isGlobalConfig]);

  const setConfigDataToFormData = useCallback(() => {
    const freeBets = configForView.config[eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES];

    let bet_levels = freeBets || defaultFormData.bet_levels;
    bet_levels = bet_levels
      ? Object.keys(bet_levels).reduce((prev, currency) => {
        prev[currency.toUpperCase()] = bet_levels[currency];
        return prev
      }, {})
      : {};

    setFormData({
      bet_levels,
    });
  }, [configForView.config, setFormData, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    if (!rates) return;
    setConfigDataToFormData();
  }, [setConfigDataToFormData, configForView.config, rates]);

  const setAvailableBets = useCallback((value) => {
      if (typeof value === 'function') value = value(formData.bet_levels);

    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES, value);
  }, [setValueToConfigForView, configForView.type, formData]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES);
  }, [toggleOverriddenField, configForView]);

  const reset = useCallback(() => {
    setConfigDataToFormData()
  }, [setConfigDataToFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box free_bets'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CRUSH_GAMES_BET_BUTTON_VALUES]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Crush Games Bet Button Values</span>

      <DropdownLayout title={'Button Values'}>
        <div className={'dropdown_body'}>
          <BetLevels ref={betLevelsRef} config={configForView} canChangeBlock={canChangeBlock} betLevelsData={formData.bet_levels}
                     availableBets={formData.bet_levels} setAvailableBets={setAvailableBets}/>
        </div>
      </DropdownLayout>
    </div>
  );
}));
