import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OperatorConfigEditor from './sections/operatorConfigEditor';
import { useDispatch, useSelector } from 'react-redux';
import { managerRest } from '../../index';
import { partnerConfigSelectors } from '../../redux/partnersConfig/selectors';
import { modalActions } from '../../redux/modals/reducer';
import { modalTypes } from '../../redux/modals/modalTypes';
import SearchSelectFilter from '../filters/searchSelectFilter';
import { operatorsConfigsSelectors } from '../../redux/operatorsConfigs/selectors';
import eOperatorsConfigsFieldName from './eOperatorsConfigsFieldName';
import OperatorConfigFiltersProvider from '../../utils/hooks/context/operatorConfigFiltersContext';

const eOperatorsConfigsType = {
  EOCT_GLOBAL: 'global',
  EOCT_OPERATOR: 'operator',
  EOCT_CASINO: 'casino',
  EOCT_GAME_GLOBAL: 'game_global',
  EOCT_GAME_OPERATOR: 'game_operator',
  EOCT_GAME_CASINO: 'game_casino',
}

const eOperatorsConfigsPriority = [
  eOperatorsConfigsType.EOCT_GLOBAL,
  eOperatorsConfigsType.EOCT_GAME_GLOBAL,
  eOperatorsConfigsType.EOCT_OPERATOR,
  eOperatorsConfigsType.EOCT_GAME_OPERATOR,
  eOperatorsConfigsType.EOCT_CASINO,
  eOperatorsConfigsType.EOCT_GAME_CASINO,
]

const requireFieldsForConfigTypes = {
  [eOperatorsConfigsType.EOCT_GLOBAL]: [],
  [eOperatorsConfigsType.EOCT_GAME_GLOBAL]: ['bundle'],
  [eOperatorsConfigsType.EOCT_OPERATOR]: ['operator_id'],
  [eOperatorsConfigsType.EOCT_GAME_OPERATOR]: ['bundle', 'operator_id'],
  [eOperatorsConfigsType.EOCT_CASINO]: ['operator_id', 'casino_id'],
  [eOperatorsConfigsType.EOCT_GAME_CASINO]: ['bundle', 'operator_id', 'casino_id'],
}

export default React.memo(function OperatorsConfigs() {
  const dispatch = useDispatch();
  const configs = useSelector(operatorsConfigsSelectors.getConfigs);
  const operators = useSelector(partnerConfigSelectors.getOperators);
  const gameList = useSelector(partnerConfigSelectors.getGameList);
  const [defaultPageTitle] = useState(document.title);
  const defaultFilterData = useMemo(() => ({
    operator_id: '1',
    casino_id: '',
    bundle: '',
  }), []);
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [previousFilterData, setPreviousFilterData] = useState(defaultFilterData);
  const [visibleConfigs, setVisibleConfigs] = useState({ [eOperatorsConfigsType.EOCT_OPERATOR]: true });
  const [saveFunctionPool, setSaveFunctionPool] = useState({});
  const [configsForView, setConfigsForView] = useState({});

  const createConfigsForView = useCallback((filter = filterData) => {
    if (!configs) return {};
    let prevConfigType;
    return eOperatorsConfigsPriority
      .reduce((prev, configType, index) => {
        const doNeedToCreateConfig = requireFieldsForConfigTypes[configType].every(field => filter[field]);
        if (!doNeedToCreateConfig) return prev;
        const configData = configs[configType] || {};

        const lastConfig = prev[prevConfigType] || {
          config: Object.values(eOperatorsConfigsFieldName).reduce((prev, fieldName) => {
            prev[fieldName] = undefined;
            return prev
          }, {}),
          map: Object.values(eOperatorsConfigsFieldName).reduce((prev, fieldName) => {
            prev[fieldName] = eOperatorsConfigsType.EOCT_GLOBAL
            return prev
          }, {})
        }

        const configForView = {
          type: configType,
          config: { ...lastConfig.config },
          map: { ...lastConfig.map },
          isGlobalConfig: configType === eOperatorsConfigsType.EOCT_GLOBAL,
          editorRef: React.createRef()
        };

        Object.keys(configData).map(key => {
          configForView.config[key] = configData[key];
          configForView.map[key] = configType;
        });

        prev[configType] = configForView;
        prevConfigType = configType;
        return prev;
      }, {});
  }, [configs, filterData]);

  const updateConfigForViewByOverriddenField = useCallback((configsForView) => {
    return Object.values(configsForView).reduce((prev, config) => {
      prev[config.type] = {
        ...configsForView[config.type],
        config: Object.keys(config.config).reduce((prev, key) => {
          prev[key] = configsForView[config.map[key]].config[key];
          return prev;
        }, {})
      }
      return prev;
    }, {});
  }, []);

  const setValueToConfigForView = useCallback((configType, fieldName, value) => {
    setConfigsForView(prev => {
      return updateConfigForViewByOverriddenField({
        ...prev,
        [configType]: {
          ...prev[configType],
          config: {
            ...prev[configType].config,
            [fieldName]: value
          }
        }
      })
    });
  }, [setConfigsForView, updateConfigForViewByOverriddenField])

  const toggleOverriddenField = useCallback((configType, fieldsName) => {
    if (!Array.isArray(fieldsName)) fieldsName = [fieldsName];
    const fieldName = fieldsName[0];
    setConfigsForView(prev => {
      const prevConfig = prev[configType].map;
      const newConfigs = {};
      if (prevConfig[fieldName] === configType) {
        const thisConfigIndex = eOperatorsConfigsPriority.findIndex(type => type === configType);
        const previousConfigType = eOperatorsConfigsPriority.slice(0, thisConfigIndex).reverse().find(type => prev[type]) || eOperatorsConfigsType.EOCT_GLOBAL;
        const previousConfigValueForThisField = prev[previousConfigType]?.map[fieldName] || eOperatorsConfigsType.EOCT_GLOBAL;
        const configsForChange = eOperatorsConfigsPriority.slice(thisConfigIndex).filter(type => prev[type]);
        configsForChange.forEach(type => {
          if (prev[type].map[fieldName] === configType) {
            const newMap = fieldsName.reduce((prevObj, field) => {
              prevObj[field] = previousConfigValueForThisField;
              return prevObj;
            }, {});
            newConfigs[type] = {
              ...prev[type],
              map: {
                ...prev[type].map,
                ...newMap
              }
            }
          }
        });
      } else {
        const thisConfigIndex = eOperatorsConfigsPriority.findIndex(type => type === configType);
        const configsForChange = eOperatorsConfigsPriority.slice(thisConfigIndex);
        configsForChange.forEach(type => {
          if (prev[type] && prev[type].map[fieldName] !== type) {
            const newMap = fieldsName.reduce((prevObj, field) => {
              prevObj[field] = configType;
              return prevObj;
            }, {});
            newConfigs[type] = {
              ...prev[type],
              map: {
                ...prev[type].map,
                ...newMap
              }
            }
          }
        });
      }
      return updateConfigForViewByOverriddenField({
        ...prev,
        ...newConfigs
      })
    });
  }, [setConfigsForView]);

  const operatorsOptions = useMemo(() => {
    if (!operators) return [];
    return Object.keys(operators).map(key => `${key}: ${operators[key].name}`)
  }, [operators]);

  const casinosOptions = useMemo(() => {
    if (!operators || !operators[filterData.operator_id]) return [];
    return operators[filterData.operator_id].casino_list
  }, [operators, filterData.operator_id]);

  const gamesOptions = useMemo(() => {
    if (!gameList) return [];
    return gameList.map(gameData => gameData.bundle);
  }, [gameList]);

  const getConfigs = useCallback(async (event, force) => {
    if (!force) {
      const haveChange = JSON.stringify(Object.values(configsForView).map(config => [config.config, config.map])) !== JSON.stringify(Object.values(createConfigsForView(previousFilterData)).map(config => [config.config, config.map]));
      if (haveChange) {
        await new Promise((resolve, reject) => {
          dispatch(modalActions.showModal(modalTypes.CONFIRM, {
            text: 'Changes that you made may not be saved',
            buttons: [
              { label: 'Cancel' },
              { label: 'OK', callback: resolve }
            ]
          }));
        });
      }
    }
    managerRest.getOperatorsConfigs(filterData.operator_id, filterData.casino_id, filterData.bundle);
    setPreviousFilterData(filterData);
  }, [filterData, configsForView, createConfigsForView, dispatch, previousFilterData, setPreviousFilterData]);

  useEffect(() => {
    Promise.all([
      managerRest.getOperators(),
      managerRest.getRates(),
      managerRest.getGameList(['onlyplay']),
      managerRest.getCertifications(),
    ]).then(() => {
      void getConfigs(undefined, true);
    })
  }, []);

  useEffect(() => {
    if (!configs || !operators) return;
    let serverPrefix = '';
    if (/.stage/.test(managerRest.domain)) {
      serverPrefix = 'STAGE';
    } else if (/.dev/.test(managerRest.domain)) {
      serverPrefix = 'DEV';
    } else if (/localhost|0.0.0.0/.test(managerRest.domain)) {
      serverPrefix = 'LOCALHOST';
    } else {
      serverPrefix = 'PROD';
    }
    document.title = `(${filterData.operator_id}) ${operators[filterData.operator_id]?.name} ${filterData.casino_id ? '/ ' + filterData.casino_id : ''} - ${serverPrefix}`;
    return () => {
      document.title = defaultPageTitle;
    }
  }, [configs, operators]);

  const checkAndGetConfigForSending = useCallback((config) => {
    let data = {};
    Object.keys(saveFunctionPool[config.type]).forEach((key) => {
      if (config.map[key] === config.type) {
        data = { ...data, ...saveFunctionPool[config.type][key]() }
      }
    });
    if (data[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS] && Object.keys(data[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS]).length === 0) {
      dispatch(modalActions.showModal(modalTypes.ERROR, `"${eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS}" field is empty in the "${config.type}" config`));
      return new Promise((resolve, reject) => reject());
    }
    return new Promise((resolve, reject) => {
      dispatch(modalActions.showModal(modalTypes.COMPARE_JSON, {
        prevJson: configs[config.type],
        newJson: data,
        title: config.type.replace('_', ' ').toUpperCase(),
        callback: () => resolve(data),
        onClose: reject
      }));
    });
  }, [saveFunctionPool, configs, dispatch]);

  const save = useCallback(async () => {
    let data = {};
    for (const configType in configsForView) {
      data[configType] = await checkAndGetConfigForSending(configsForView[configType]);
    }
    console.log('Operators Configs', data);
    console.log(JSON.stringify(data));
    managerRest.setOperatorsConfigs(data, filterData.operator_id, filterData.casino_id, filterData.bundle);
  }, [checkAndGetConfigForSending, configsForView, filterData]);

  const reset = useCallback(() => {
    const viewConfigs = createConfigsForView();
    setConfigsForView(viewConfigs);
    Object.values(viewConfigs).forEach((currentConfig => {
      currentConfig.editorRef.current && currentConfig.editorRef.current.reset();
    }))
  }, [setConfigsForView, createConfigsForView]);

  useEffect(() => {
    reset()
  }, [configs]);

  const selectOperator = useCallback((updateFn) => {
    const operator_id = updateFn().operator_id.replace(/:.*$/, '');
    setFilterData(prev => ({
      ...prev,
      operator_id
    }))
  }, [setFilterData]);

  const toggleConfigVisible = useCallback((configType) => {
    setVisibleConfigs(prev => ({
      ...prev,
      [configType]: !prev[configType]
    }))
  }, [setVisibleConfigs]);

  return (
    <OperatorConfigFiltersProvider value={previousFilterData}>
      <div className={'page'}>
        <span className={'page_title'}>Operators configs</span>
        <div className={'operators_configs_controls filters_block'}>
          <div className={'operators_configs_controls_column'}>
            <SearchSelectFilter title={'Operator'} fieldName={'operator_id'} options={operatorsOptions}
                                callback={selectOperator} defaultValue={'1: LOCAL'}
                                className={filterData.operator_id !== previousFilterData.operator_id ? 'operators_configs_filter_warning' : ''}
            />
            <div
              className={'operators_configs_controls_previous_value'}>{previousFilterData.operator_id ? operatorsOptions.find(option => option.match(previousFilterData.operator_id)) : ''}</div>
          </div>

          <div className={'operators_configs_controls_column'}>
            <SearchSelectFilter title={'Casino'} fieldName={'casino_id'} options={casinosOptions}
                                callback={setFilterData}
                                className={filterData.casino_id !== previousFilterData.casino_id ? 'operators_configs_filter_warning' : ''}
            />
            <div className={'operators_configs_controls_previous_value'}>{previousFilterData.casino_id}</div>
          </div>

          <div className={'operators_configs_controls_column'}>
            <SearchSelectFilter title={'Game'} fieldName={'bundle'} options={gamesOptions}
                                callback={setFilterData}
                                className={filterData.bundle !== previousFilterData.bundle ? 'operators_configs_filter_warning' : ''}
            />
            <div className={'operators_configs_controls_previous_value'}>{previousFilterData.bundle}</div>
          </div>
          <button onClick={getConfigs} className={'button button_blue'}>
            GET
          </button>
          <button onClick={save} className={'button button_blue'}>
            Save
          </button>
          <button onClick={reset} className={'button button_blue'}>
            Reset
          </button>
        </div>

        <div className={'operators_configs'}>
          {Object.values(configsForView).map((configForView) =>
            <div key={configForView.type} className={'operators_configs_config'}>
              <div onClick={() => toggleConfigVisible(configForView.type)} className="operators_configs_config_title">
                {configForView.type.replace('_', ' ')}
              </div>
              <OperatorConfigEditor ref={configForView.editorRef}
                                    setSaveFunctionPool={setSaveFunctionPool}
                                    configForView={configForView}
                                    overriddenBlocks={configForView.map}
                                    toggleOverriddenField={toggleOverriddenField}
                                    className={visibleConfigs[configForView.type] ? '' : 'hidden'}
                                    setValueToConfigForView={setValueToConfigForView}
              />
            </div>
          )}
        </div>
      </div>
    </OperatorConfigFiltersProvider>
  );
})
